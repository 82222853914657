<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="400px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="close"
    class="global-org-vue--new-form-dialog"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="100px"
      class="global-org-vue--new-form"
      size="small"
    >
      <el-form-item label="标识符" prop="identifier">
        <el-input v-model="form.identifier" readonly disabled />
      </el-form-item>
      <el-form-item label="说明" prop="description">
        <el-input
          v-model="form.description"
          placeholder="请输入说明"
          maxlength="20"
          show-word-limit
        />
      </el-form-item>
      <el-form-item label="css" prop="env">
        <el-input
          v-model="form.css"
          placeholder="请输入样式"
          show-word-limit
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="submitForm"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { fetchSetting } from "@/api/setting";

export default {
  name: "EditBgGradient",
  props: {
    // 当前类型
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialogVisible: false,
      form: {
        identifier: "bg_gradient",
        system_set_id: "",
        description: "",
        css: "",
      },
      rules: {
        css: [
          { required: true, message: "请设置背景", trigger: "blur" },
        ],
      },
      loading: false,
    };
  },
  computed: {
    title() {
      return this.type == "add" ? "添加背景渐变" : "修改背景渐变";
    },
  },
  methods: {
    // 打开弹窗
    open(page) {
      this.dialogVisible = true;
      this.$nextTick(() => {
        if (this.type == "mod") {
          this.form = {
            identifier: page.identifier,
            system_set_id: page.system_set_id,
            description: page.description,
            ...page.value,
          };
        }
      });
    },
    // 关闭
    close() {
      this.dialogVisible = false;
      this.$refs.form.resetFields();
      this.form = this.$options.data().form;
    },
    // 提交
    submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let params = {
            description: this.form.description,
            identifier: this.form.identifier,
            type: this.type,
            value: {
              css: this.form.css,
            },
          };
          if (this.type == "mod") {
            params.system_set_id = this.form.system_set_id;
          }
          this.loading = true;
          fetchSetting(params)
            .then(() => {
              this.loading = false;
              this.$message.success("操作成功");
              this.$emit("refresh");
              this.close();
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
