import { render, staticRenderFns } from "./edit.vue?vue&type=template&id=05543d6c"
import script from "./edit.vue?vue&type=script&lang=js"
export * from "./edit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_vue-template-compiler@2.7.16_webpack@5.94.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports